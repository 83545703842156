import React, { useContext, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import MultiStep, { TMultiStepRef } from "@/components/MultiStep";
import ActivityPopin from "@/components/PopinActivity";
import PopinError from "@/components/PopinError";

import {
  ECustomerGender,
  TCustomerAnswerResponse,
  TCustomerSelfieResponse,
  TProcessType,
} from "@/helpers/API/requests/interfaces/customer";
import {
  customerAnswer,
  customerCartValidation,
  customerPurchasedProducts,
  customerSelfie,
  customerSendRecap,
  sendOperatorCode,
} from "@/helpers/API/requests/customer";

import { AuthContext } from "@/providers/AuthProvider";
import { EUserRole } from "@/providers/AuthProvider/interfaces";
import { LocaleContext } from "@/providers/LocaleProvider";

import ERoutes from "@/routers/AuthRouter/routes";

import PurchaseValidationStep from "@/screens/Auth/ProcessAI/PurchaseValidationStep";

import CartStep from "./CartStep";
import CodeValidationStep from "./CodeValidationStep";
import DiagnosisStep from "./DiagnosisStep";
import HomeStep from "./HomeStep";
import PersonalizationStep from "./PersonalizationStep";
import PictureStep from "./PictureStep";
import RecapStep from "./RecapStep";
import RecommendationStep from "./RecommendationStep";
import Styles from "./styles.module.scss";
import { TProductInCart } from "./RecommendationStep/interfaces";
import { PicturePreparationStep } from "./PicturePreparationStep";

type TProcessContext = {
  cartValidation: any;
  diagnosis: TCustomerSelfieResponse | null;
  goToHome: () => void;
  picture: string;
  recommendations: TCustomerAnswerResponse | null;
  resetClock: () => void;
  sendPicture: (image: string) => void;
  downloadPictureRoute: boolean;
};

export const ProcessContext = React.createContext<TProcessContext>({
  cartValidation: null,
  diagnosis: null,
  goToHome: () => {},
  picture: "",
  recommendations: null,
  resetClock: () => {},
  sendPicture: () => {},
  downloadPictureRoute: false,
});

const mainCounter = 290;
const popinCounter = 121;

type LocationState = {
  state: {
    step: number;
  };
};

const ProcessAI: React.FC = () => {
  const counter = React.useRef<number>(mainCounter);

  const multiStep = React.useRef<TMultiStepRef>(null);
  const cart = React.useRef<TProductInCart[] | null>(null);

  const [picture, setPicture] = useState("");
  const [errorHautAiPopin, setErrorHautAiPopin] = useState<boolean>(false);
  const [stepTransition, setStepTransition] = React.useState<boolean>(false);
  const [isTransitionPrev, setIsTransitionPrev] =
    React.useState<boolean>(false);
  const [diagnosis, setDiagnosis] = React.useState<any>(null);
  const [customerEmail, setCustomerEmail] = React.useState<string>("");
  const [customerGender, setCustomerGender] = React.useState<string>("");
  const [recommendations, setRecommendations] = React.useState<any>(undefined);
  const [cartValidation, setCartValidation] = React.useState<any>(undefined);
  const [cartId, setCartId] = React.useState<any>(null);
  const [showActivityPopin, setShowActivityPopin] =
    React.useState<boolean>(false);
  const [downloadPictureRoute, setDownloadPictureRoute] =
    useState<boolean>(false);
  const location = useLocation() as LocationState;

  const localeContext = React.useContext(LocaleContext);

  const routerLocation = useLocation();
  const campaignToken = new URLSearchParams(routerLocation.search).get(
    "campaign"
  );
  const processType: TProcessType = campaignToken ? "campaign" : "pharmacy";

  const auth = useContext(AuthContext);
  const router = useNavigate();
  React.useEffect(() => {
    const { user } = auth;
    const isAnimator = user?.roles.includes(EUserRole.ANIMATOR);
    const isLab = user?.roles.includes(EUserRole.LAB);
    if (
      (isAnimator || isLab) &&
      (null === campaignToken || "" === campaignToken)
    ) {
      router(ERoutes.Animator);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignToken]);

  const resetClock = React.useCallback(() => {
    counter.current = mainCounter;
    setShowActivityPopin(false);
  }, []);

  const goToHome = React.useCallback(() => {
    setErrorHautAiPopin(false);
    multiStep.current!.goToStep(1);
    resetClock();
  }, [resetClock]);

  React.useEffect(() => {
    const clock = setInterval(() => {
      if ((multiStep.current?.currentStep?.index || 1) > 1) {
        if (counter.current > popinCounter) {
          counter.current -= 1;
        } else if (counter.current > 0) {
          setShowActivityPopin(true);
          counter.current -= 1;
        } else {
          setShowActivityPopin(false);
          goToHome();
        }
      }
    }, 1000);

    return () => {
      clearInterval(clock);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter.current]);

  const sendPicture = (image: string) => {
    const pictureObject = {
      base64: image,
    };

    setPicture(image);
    customerSelfie(processType, pictureObject, localeContext.locale)
      .then(setDiagnosis)
      .catch((err) => {
        console.error(err);
        setErrorHautAiPopin(true);
      });
  };

  const isMobile = window.innerWidth <= 768;

  const sendQuestionnaire = (answers: any, _diagnosis: any) => {
    answers.scores = _diagnosis.results.reduce(
      (res: any, { slug, score }: any) => {
        return {
          ...res,
          [slug]: score,
        };
      },
      {}
    );

    setCustomerEmail(answers.customer_email);
    setCustomerGender(answers.gender);
    answers.is_mobile = isMobile;

    answers.campaign = campaignToken;

    customerAnswer(processType, answers, localeContext.locale).then(
      (response) => {
        setRecommendations(response);
        setCartId(response.cart_id);
      }
    );
  };

  const sendCartValidation = function (products: { id: number }[]) {
    const productIds = products.map((product) => product.id);
    const cart = {
      checked_products: productIds,
    };

    customerCartValidation(processType, cartId, cart)
      .then((response) => {
        setCartValidation(response);
      })
      .catch(() => {
        setCartValidation({ success: false });
      });
  };

  const startDownloadPictureRoute = () => {
    setDownloadPictureRoute(true);
    multiStep.current!.next();
  };

  const sendRecap = () => {
    const recap = {
      email: customerEmail,
      is_men: customerGender === ECustomerGender.MAN,
      items: diagnosis?.results.map((r: any) => ({
        title: r.slug,
        score: r.score,
      })),
      cartId,
    };

    customerSendRecap(processType, recap, localeContext.locale);
  };

  const validatePurchases = (selectedProducts: number[]) => {
    const body = {
      ids: selectedProducts,
    };

    customerPurchasedProducts(cartId, body);
  };

  const sendCode = (cartId: number, operatorCode: number) => {
    sendOperatorCode(cartId, operatorCode);
  };

  return (
    <ProcessContext.Provider
      value={{
        picture,
        diagnosis,
        recommendations,
        cartValidation,
        goToHome,
        resetClock,
        sendPicture,
        downloadPictureRoute,
      }}
    >
      <ActivityPopin
        active={showActivityPopin}
        initialCounter={popinCounter - 1}
        onClick={resetClock}
      />
      <div
        className={`${Styles["processIA"]} ${
          stepTransition ? Styles["is-transition"] : ""
        } ${isTransitionPrev ? Styles["is-prev"] : ""}`}
      >
        <MultiStep
          initialStep={location.state?.step || undefined}
          ref={multiStep}
          onStepChange={resetClock}
          delay={300}
          onPrevStep={() => {
            setIsTransitionPrev(true);
          }}
          onTransitionStart={() => {
            setTimeout(() => {
              setStepTransition(true);
            }, 50);
          }}
          onTransitionEnded={() => {
            setIsTransitionPrev(false);
            setStepTransition(false);
          }}
        >
          <MultiStep.Step name="home">
            <div className={Styles["processIA__step"]}>
              <HomeStep
                onSubmit={() => {
                  setDiagnosis(null);
                  setCustomerEmail("");
                  setRecommendations(undefined);
                  setCartValidation(undefined);
                  multiStep.current!.next();
                }}
                hideLogout={false}
              />
            </div>
          </MultiStep.Step>

          <MultiStep.Step name="prepareForPicture">
            <div className={Styles["processIA__step"]}>
              <PicturePreparationStep
                onSubmit={() => {
                  multiStep.current!.next();
                  setPicture("");
                }}
                setPicture={setPicture}
                startDownloadPictureRoute={startDownloadPictureRoute}
              />
            </div>
          </MultiStep.Step>

          <MultiStep.Step name="takePicture">
            <div className={Styles["processIA__step"]}>
              <PictureStep
                onSubmit={() => {
                  multiStep.current!.next();
                }}
                onError={goToHome}
                goToPreviousStep={() => {
                  multiStep.current!.prev();
                  setDownloadPictureRoute(false);
                }}
              />
            </div>
          </MultiStep.Step>

          <MultiStep.Step name="personalization">
            <div className={Styles["processIA__step"]}>
              <PersonalizationStep
                onStepChange={resetClock}
                onSubmit={(answers, _diagnosis) => {
                  sendQuestionnaire(answers, _diagnosis);
                  multiStep.current!.next();
                }}
              />
            </div>
          </MultiStep.Step>

          <MultiStep.Step name="diagnosis">
            <div className={Styles["processIA__step"]}>
              <DiagnosisStep
                onSubmit={() => {
                  multiStep.current!.next();
                }}
              />
            </div>
          </MultiStep.Step>

          <MultiStep.Step name="recommendation">
            <div className={Styles["processIA__step"]}>
              <RecommendationStep
                products={cart.current}
                onSubmit={(products) => {
                  cart.current = products;
                  multiStep.current!.next();
                }}
              />
            </div>
          </MultiStep.Step>

          <MultiStep.Step name="cart">
            <div className={Styles["processIA__step"]}>
              <CartStep
                products={cart.current!}
                prev={multiStep.current?.prev}
                onSubmit={() => {
                  if (cart.current) {
                    sendCartValidation(cart.current);
                    multiStep.current!.next();
                  }
                }}
              />
            </div>
          </MultiStep.Step>

          <MultiStep.Step name="recap">
            <div className={Styles["processIA__step"]}>
              <RecapStep
                onSubmit={sendRecap}
                animatorCTA={() => multiStep.current!.next()}
                codeValidationCTA={() =>
                  multiStep.current!.goToStep("codeValidation")
                }
              />
            </div>
          </MultiStep.Step>

          <MultiStep.Step name="purchaseValidation">
            <div className={Styles["processIA__step"]}>
              <PurchaseValidationStep
                products={cart.current!}
                onSubmit={validatePurchases}
              />
            </div>
          </MultiStep.Step>

          <MultiStep.Step name="codeValidation">
            <div className={Styles["processIA__step"]}>
              <CodeValidationStep cartId={cartId} onSubmit={sendCode} />
            </div>
          </MultiStep.Step>
        </MultiStep>

        <PopinError
          active={errorHautAiPopin}
          onClose={goToHome}
          title={"global.popinErrorHautAi.title"}
          description={"global.popinErrorHautAi.description"}
          backHome={"global.popinErrorHautAi.backHome"}
        />
      </div>
    </ProcessContext.Provider>
  );
};

export default ProcessAI;
