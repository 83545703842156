import { ChangeEvent, FunctionComponent } from "react";

import AnalyseImage from "@/globals/images/analyse.png";
import { TPicturePreprationStep } from "./interfaces";

import Styles from "./styles.module.scss";
import Inner from "@/components/Inner";
import Button from "@/components/Button";
import LocaleText from "@/components/Intl/LocaleText";
import { fileToBase64 } from "@/helpers/liqa";
import Title from "@/components/Title";
import Breadcrumb from "@/components/Breadcrumb";

export const PicturePreparationStep: FunctionComponent<TPicturePreprationStep> =
  ({ onSubmit, setPicture, startDownloadPictureRoute }) => {
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        const imageString = await fileToBase64(file);
        setPicture(imageString);
        startDownloadPictureRoute();
      }
    };

    return (
      <>
        <Breadcrumb step={1} />
        <Inner className={Styles.picturePreparationStep}>
          <div className={Styles.picturePreparationStep__body}>
            <div className={Styles.picturePreparationStep__visual}>
              <img
                className={Styles.picturePreparationStep__visual__image}
                src={AnalyseImage}
                alt=""
              />
            </div>
            <div className={Styles.picturePreparationStep__textContent}>
              <Title type={2} className={Styles.picturePreparationStep__title}>
                <span className={Styles.picturePreparationStep__title__colored}>
                  <LocaleText>page.picturePreparationStep.title_1</LocaleText>
                </span>
                <LocaleText>page.picturePreparationStep.title_2</LocaleText>
              </Title>
              <LocaleText>page.picturePreparationStep.instruction</LocaleText>
            </div>
            <div className={Styles.picturePreparationStep__buttonContainer}>
              <Button
                onClick={onSubmit}
                // rounded
              >
                <LocaleText>page.picturePreparationStep.submit</LocaleText>
              </Button>
              <div>
                <input
                  className={Styles.picturePreparationStep__fileInput}
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  id="photo-upload"
                />
                <label
                  htmlFor="photo-upload"
                  className={Styles.picturePreparationStep__downloadPhotoButton}
                >
                  <LocaleText>page.picturePreparationStep.download</LocaleText>
                </label>
              </div>
            </div>
          </div>
        </Inner>
      </>
    );
  };
