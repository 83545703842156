import React, { useContext } from "react";

import Button from "@/components/Button";
import LocaleText from "@/components/Intl/LocaleText";
import Styles from "./styles.module.scss";

import { TRecapStep } from "./interfaces";
import Title from "@/components/Title";
import Inner from "@/components/Inner";
import RecapDiagnosisCard from "./RecapDiagnosisCard";
import { ProcessContext } from "..";
import Spinner from "@/components/Spinner";
import Icon from "@/components/Icon";
import { AuthContext } from "@/providers/AuthProvider";
import { EUserRole } from "@/providers/AuthProvider/interfaces";
import { CampaignContext } from "@/providers/AuthProvider/campaignInfoProvider";
import { TCustomerSelfieResponse } from "@/helpers/API/requests/interfaces/customer";

const RecapStep: React.FC<TRecapStep> = ({
  onSubmit,
  animatorCTA,
  codeValidationCTA,
}) => {
  const { isLaboratory } = React.useContext(CampaignContext);
  const [emailSent, setEmailSent] = React.useState(false);

  const {
    picture,
    cartValidation: { success: cartValidation } = { success: null },
    diagnosis,
  } = React.useContext(ProcessContext);
  const { results } = diagnosis as TCustomerSelfieResponse;

  const sortedResults = React.useMemo(
    () =>
      results
        .slice()
        .sort((a: any) =>
          ["Cernes", "Rides", "Eclat"].includes(a.name) ? -1 : 0
        )
        .slice(0, 3),
    [results]
  );

  const auth = useContext(AuthContext);
  const { user } = auth;

  return cartValidation !== null ? (
    <div className={Styles["recapStep"]}>
      {cartValidation ? (
        <Inner className={Styles["recapStep__heading"]}>
          <Title className={Styles["recapStep__title"]}>
            <LocaleText>page.recapStep.title</LocaleText>
          </Title>
          {!isLaboratory && (
            <p className={Styles["recapStep__subtitle"]}>
              <LocaleText>page.recapStep.subtitle</LocaleText>
            </p>
          )}
        </Inner>
      ) : (
        <Inner className={Styles["recapStep__heading"]}>
          <Title className={Styles["recapStep__title"]}>
            <LocaleText>page.recapStep.error.title</LocaleText>
          </Title>
          <p className={Styles["recapStep__subtitle"]}>
            <LocaleText>page.recapStep.error.subtitle</LocaleText>
          </p>
        </Inner>
      )}

      <div className={Styles["recapStep__body"]}>
        <p className={Styles["recapStep__body-title"]}>
          <LocaleText>page.recapStep.bodyTitle</LocaleText>
        </p>
        <Inner className={`${Styles["recapStep__body-cards"]}`}>
          {sortedResults.map((card: any, cIndex: number) => (
            <RecapDiagnosisCard
              key={"recapDiagnosisCard-" + cIndex}
              card={card}
              picture={picture ?? ""}
            />
          ))}
        </Inner>
        <p className={Styles["recapStep__body-description"]}>
          <LocaleText>page.recapStep.description</LocaleText>
        </p>
        <Button
          disabled={emailSent}
          size="medium"
          outline={true}
          white={true}
          className={Styles["recapStep__body-cta"]}
          onClick={() => {
            setEmailSent(true);
            onSubmit();
          }}
        >
          {emailSent ? (
            <>
              <Icon icon="check" />
              <LocaleText>page.recapStep.sent</LocaleText>
            </>
          ) : (
            <LocaleText>page.recapStep.cta</LocaleText>
          )}
        </Button>
      </div>
      <Inner className={Styles["recapStep__footer"]}>
        {user?.roles.includes(EUserRole.ANIMATOR) ||
        user?.roles.includes(EUserRole.LAB) ? (
          <div
            onClick={() => {
              animatorCTA();
            }}
            className={Styles["recapStep__footerText"]}
          >
            <LocaleText>page.recapStep.validatePurchase</LocaleText>
          </div>
        ) : isLaboratory ? null : (
          <div
            onClick={() => codeValidationCTA()}
            className={Styles["recapStep__footerText"]}
          >
            <LocaleText>page.recapStep.validateWithCode</LocaleText>
          </div>
        )}
      </Inner>
    </div>
  ) : (
    <div className={Styles["recapStep"]}>
      <Spinner className={Styles["recapStep__loading"]} />
    </div>
  );
};

export default RecapStep;
