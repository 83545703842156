const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      if (typeof result === "string") {
        resolve(result);
      } else {
        reject(new Error("Failed to convert Blob to base64 string"));
      }
    };
    reader.onerror = () => reject(new Error("FileReader error"));
    reader.readAsDataURL(blob);
  });
};

export const provideBase64Image = async (event: any): Promise<string> => {
  const capture = event.detail;
  const imageBlob: Blob = await capture.blob();
  const base64 = await blobToBase64(imageBlob);
  return base64;
};

export const fileToBase64 = async (file: File): Promise<string> => {
  return (await blobToBase64(file)) as string;
};
