import { FunctionComponent, useContext } from "react";
import { isMobile } from "react-device-detect";
import PictureMobile from "@/components/PictureMobile";
import PictureNonMobile from "@/components/PictureNonMobile";
import { TPictureStep } from "./interfaces";
import { ProcessContext } from "..";

const PictureStep: FunctionComponent<TPictureStep> = ({
  onSubmit,
  onError,
  goToPreviousStep,
}) => {
  const { downloadPictureRoute } = useContext(ProcessContext);

  return downloadPictureRoute || !isMobile ? (
    <PictureNonMobile
      onSubmit={onSubmit}
      onError={onError}
      goToPreviousStep={goToPreviousStep}
    />
  ) : (
    <PictureMobile onSubmit={onSubmit} onError={onError} />
  );
};

export default PictureStep;
